/* You can add global styles to this file, and also import other style files */
/* @import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css'; */


body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.scrollbar {
  overflow: auto !important;
}

.notification-margin {
  margin: 0 32px 32px 0;
}

.wb-padding-top-summary {
  padding-block-start: 16px;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.spacing-s {
  margin-top: var(--wb-spacing-s);
  margin-bottom: var(--wb-spacing-s);
}

.spacing-24 {
  margin: 24px 0;
}

.padding-8 {
  padding: 8px;
}

.spacing-xxs {
  margin-top: var(--wb-spacing-xxs);
  margin-bottom: var(--wb-spacing-xxs);
}

.spacing-top-xxs {
  margin-top: var(--wb-spacing-xxs);
}

.center-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-section {
  display: flex;
  align-items: center;
}

.icon-color {
  color: var(--wb-blue-45);
}

.icon-color:hover {
  border: 1px;
  border-color: var(--wb-blue-45);
}

.add-settings:hover {
  text-decoration: underline;
  cursor: pointer;
}

.button-spacing {
  margin-left: var(--wb-spacing-s);
  margin-right: var(--wb-spacing-s);
}


.grid-margin {
  margin: 0px auto;
}

.footer-pos {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.content {
  min-height: 50%;
  height: auto;
  margin-bottom: 100px;
}

app-root {
  min-height: 100%;
  overflow: auto;
}

@media screen and (max-height: 1079px) {
  app-root {
    overflow: auto;
  }
}

.show {
  display: block
}

.centered-element {
  position: fixed;
  /* Set the position to fixed to position it relative to the viewport */
  top: 50%;
  /* Move the top edge to the vertical center */
  left: 50%;
  /* Move the left edge to the horizontal center */
  transform: translate(-50%, -50%);
  /* Use translate to center the element perfectly */
}

.app-grid {
  width: 90%;
}

.sub-grid {
  width: 100%;
}

.no-padding {
  padding: 0;
}

.spacing-top-xs {
  margin-top: var(--wb-spacing-xs);
}

.spacing-top-s {
  margin-top: var(--wb-spacing-s);
}

.spacing-top-16 {
  margin-top: 16px;
}

.spacing-top-12 {
  margin-top: 12px;
}

.spacing-bot-24 {
  margin-bottom: 24px;
}

.spacing-bot-16 {
  margin-bottom: 16px;
}

.spacing-left-8 {
  margin-left: var(--wb-spacing-xxs);
}

.wb-pagination {
  justify-content: right;
  padding-top: 24px;
}

.ag-theme-wb .ag-details-row {
  padding: 8px 35px 8px 19px;
}

.spacing-xs {
  margin-bottom: var(--wb-spacing-xs);
}

/* .wb-tooltip-overlay {
  background-color: white !important;
} */

.gutter {
  gap: 40px
}

.card-spacing {
  margin-top: var(--wb-spacing-xs);
  margin-right: 8px;
  margin-bottom: var(--wb-spacing-xs);
}

.section {
  overflow: visible !important;
}
